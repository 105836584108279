import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css'; // Make sure to create a CSS file for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const Header = () => {
  return (
    <header className="header">
      <div className="logo">
        <Link to="/">SIDDHARDHA VARMA</Link> {/* Your initials or logo here */}
      </div>
      <div className="social-links">
        {/* Add your social media links here */}
        <a href="https://github.com/siddhardha99" target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={faGithub} style={{ fontSize: '30px' }}/>
        </a>
        <a href="https://www.instagram.com/siddhardha_varma_siddhu/" target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={faInstagram} style={{ fontSize: '30px' }}/>
        </a>
        <a href="https://www.linkedin.com/in/siddhardha-varma-mudunuri/" target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={faLinkedin} style={{ fontSize: '30px' }} />
        </a>
        {/* Add more links as needed */}
      </div>
    </header>
  );
};

export default Header;
