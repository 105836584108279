import React from 'react'
import AboutMe from '../components/Aboutme'

const About = () => {
  return (
    <div>
      <AboutMe/>
    </div>
  )
}

export default About