// src/components/TabNavigation.js
import React from 'react';
import { NavLink } from 'react-router-dom';
import './TabNavigation.css'; // Make sure to create a CSS file for styling

const TabNavigation = () => {
  return (
    <div className='parent-container'>
      <nav className="tab-navigation">
        <NavLink to="/" className={({ isActive }) => (isActive ? 'active-tab' : '')}>Home</NavLink>
        <NavLink to="/projects" className={({ isActive }) => (isActive ? 'active-tab' : '')}>Projects</NavLink>
        <NavLink to="/blog" className={({ isActive }) => (isActive ? 'active-tab' : '')}>Blog</NavLink>
        <NavLink to="/publications" className={({ isActive }) => (isActive ? 'active-tab' : '')}>Published Works</NavLink>
        <NavLink to="/about" className={({ isActive }) => (isActive ? 'active-tab' : '')}>About me</NavLink>
      </nav>
    </div>
  );
};

export default TabNavigation;
