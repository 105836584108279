// src/components/Project.js
import React from 'react';
import "./PublishedWork.css"

const PublishedWork = ({ title, imageUrl, description }) => {
  return (
    <article className='project'>
        <h2 className='project-title'>{title}</h2>
        {imageUrl && (
        <img
          src={imageUrl}
          alt={title}
          className="project-image"
        />
      )}
      <p className="project-summary">{description}</p>
    </article>
  );
};

export default PublishedWork;
