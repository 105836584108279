import React from 'react'
import HeroSection from '../components/HeroSection'




const Home = () => {
  return (
    <div>
        <HeroSection />
        
  
    </div>
  )
}

export default Home