// src/components/AboutMe.js
import React from 'react';
import './AboutMe.css'; // Make sure to create a CSS file for styling

const AboutMe = () => {
  return (
    <section className="about-me">
      <h2>About Me</h2>
      <div className='main'>
      <div className='image-container'>
      <img src='aboutme.png'  alt="Krishna Prasad Siddhardha Varma Mudunuri" className="profile-image" />
      </div>
      <div className='content'>
      <p>I'm aspiring Master of Science in Engineering in Artificial Intelligence from the University of North Texas, 
        with a foundation in Electronics and Communication from Vellore Institute of Technology. 
        My academic and professional journey is a testament to my dedication to pushing the boundaries of 
        AI and technology to create impactful solutions. From designing user-centric products at DigiBros to 
        pioneering innovative applications as a Co-founder of Onsync, my experience spans across UI/UX design, 
        product development, and leading-edge AI research.</p>
      <p>
        My portfolio is a gateway into my world of innovation, where each project, research endeavor, and professional milestone 
        narrates a story of challenge, discovery, and achievement. Beyond my professional pursuits, 
        I find joy and inspiration in reading, podcasting, and gaming, each hobby providing a fresh perspective 
        and fueling my creativity.
      </p>
      <p>
        Dive deeper into my journey by exploring the Skills, Projects, Research, Achievements, 
        and Experience pages. Let's embark on this journey together, exploring the possibilities that technology 
        and creativity hold, for a better tomorrow.
        </p>
        <p>
          Connect with Me: I'm always eager to collaborate, learn, and share ideas. 
          Reach out through my email at KrishnaPrasadMudunuri@my.unt.edu or connect on LinkedIn
           for opportunities to make a meaningful impact.
          </p>
          </div>

          </div>
    </section>
  );
};

export default AboutMe;




