// src/sections/Projects.js
import React, { useEffect, useState } from 'react';
import client from '../contentfulClient';
import Project from '../components/Project'; // You'll need to create this component

const Projects = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const fetchProjects = async () => {
      const response = await client.getEntries({
        content_type: 'projects',
        'fields.postProject': true, // Assuming 'postProject' is a boolean to publish/unpublish a project
      });

      setProjects(response.items);
    };

    fetchProjects();
  }, []);

  return (
    <div className="projects-container">
      {projects.map(({ fields }) => (
        <Project
          key={fields.title}
          title={fields.title}
          imageUrl={fields.projectimage?.fields.file.url}
          description={fields.projectDescription}
          files={fields.projectFile} // Assuming this is an array of assets
          explanation={fields.projectExplaination}
        />
      ))}
    </div>
  );
};

export default Projects;
