// src/sections/Blog.js
import React, { useEffect, useState } from 'react';
import client from '../contentfulClient';
import BlogPost from '../components/BlogPost'; // Adjust the import path as necessary

const Blog = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      const response = await client.getEntries({
        content_type: 'blog',
        'fields.publish': true,
      });

      setPosts(response.items);
    };

    fetchPosts();
  }, []);

  return (
    <div className="blog-container">
      {posts.map(({ fields }) => (
        <BlogPost
          key={fields.blogTitle}
          title={fields.blogTitle}
          image={fields.blogImage?.fields.file.url}
          summary={fields.blogSummary}
          author={fields.blogAuthor}
          date={fields.createdDate}
        />
      ))}
    </div>
  );
};

export default Blog;
