import React, { useEffect, useState } from 'react'
import PublishedWork from '../components/PublishedWork'
import client from '../contentfulClient';

const Publications = () => {
  const [publication,setPublication]=useState([]);

  useEffect(()=>{
    const fetchPublications =async ()=>{
      const response = await  client.getEntries({
        content_type: "publishedWorks",
        'fields.show': true,
        });
       setPublication(response.items);
    };
    fetchPublications();
  }, []);

  return (
    <div className='publication-container'>
      {publication.map(({ fields }) => (
        <PublishedWork
          key={fields.publishedTitle}
          title={fields.publishedTitle}
          imageUrl={fields.publishedImage?.fields.file.url}
          description={fields.publishedDescription}
          files={fields.publishedPdf} // Assuming this is an array of assets
          explanation={fields.linkurl}
        />
      ))}
      
    </div>
  )
}

export default Publications