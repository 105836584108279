// src/components/HeroSection.js
import React from 'react';
import './HeroSection.css'; // Make sure to create a CSS file for styling


const HeroSection = () => {
  return (
    <section className="hero">
      <div className='hero-image'>
      <img src='hero1.jpg'  alt="Krishna Prasad Siddhardha Varma Mudunuri" className="profile-image" />
      </div>
      <div className="hero-text">
        <h1>Hi, I am Siddhardha Varma Mudunuri 👋</h1>
        <p>
         I am on a journey to explore the fascinating world of Artificial Intelligence. 
         <p>This website serves as a gateway to my AI blog, portfolio, and a showcase of 
         my evolving work in the field.Here, I share my insights, experiences, and the learnings 
         I've gathered along the way. Every opinion and piece of content reflects my personal perspective, 
         born from a genuine passion for innovation and technological advancement.
         </p> 
         <h4>Welcome to my world, 
         where AI meets creativity and potential.</h4>
        </p>
        <h2>WEBSITE IN PROGRESS</h2>
      </div>
    </section>
  );
};

export default HeroSection;



