// src/contentfulClient.js
const contentful = require('contentful');

const client = contentful.createClient({
  space: '6aiinn4hpvr4', // Replace with your space ID
  accessToken: 'Y6QBw9F6J_YIOqm67N3jPgWEtvPe-9kfECKo6FsJKUc', // Replace with your access token
});

export default client;


