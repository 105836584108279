// src/components/BlogPost.js
import React from 'react';
import './BlogPost.css';


const BlogPost = ({ title, image, summary, author, date }) => {
  return (
    <article className="blog-post">
      <h2 className="post-title">{title}</h2>
      {image && (
        <img
          src={image}
          alt={title}
          className="post-image"
        />
      )}
      <p className="post-summary">{summary}</p>
      <div className="post-meta">Posted by {author} on {new Date(date).toDateString()}</div>
    </article>
  );
};

export default BlogPost;
