import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './sections/Home';
import About from './sections/About';

import Header from './components/Header';
import Blog from './sections/Blog';
import TabNavigation from './components/TabNavigation';
import Projects from './sections/Projects';
import Publications from './sections/Publications';

// Import other sections as needed

function App() {
  return (
    <Router>
      <Header />
      <div className='parent-container'>
      <TabNavigation />
      </div>
      
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/publications" element={<Publications />} />
        {/* Define other routes with their respective components */}
      </Routes>
    </Router>
  );
}

export default App;
